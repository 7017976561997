import { Axios } from "./axios";
//获取我的 Roll 房详情
export function SRollInfo(id) {
  return Axios({
    url: `/room/detail?id=${id}`,
    method: "get",
  });
}

//加入房间
export function SJoinHome(...params) {
  return Axios({
    url: `/room/join`,
    method: "post",
    data: {
      id: params[0],
      password: params[1],
    },
  });
}
